@import "~bootstrap/scss/bootstrap";
//@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu";
@import "./sidebar";

.main-wrapper {
    width: 100%;
    height: 100%;
    background: #f3f4f6;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "sidebar header" "sidebar main";
}

.layout-header {
    justify-content: stretch;
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;
}

.main-header {
    grid-area: header;
    padding: 0.5rem;
}

.main-container {
    grid-area: main;
    box-shadow: inset 2px 1px 9px 1px rgba($dark, 0.10);
    overflow: auto;
    padding-top: 4rem;
}

.sidebar-menu {
    padding-top: 0;
    flex-direction: column;
    grid-area: sidebar;
    overflow-y: auto;
}

.listnav-bg {
    margin: 0px 10px 4px 10px;
    background-color: #17a2b8;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    color: #fff;

    .material-symbols-outlined {
        font-weight: 500 !important;
    }

    .listnav-bg-txt {
        margin-left: 15px;
        color: #fff !important;

        span {
            font-family: "Poppins", sans-serif !important;
            font-weight: 500 !important;
            font-size: 0.9em !important;
        }
    }
}

.listnav-bg:hover {
    background-color: #17a2b882;
}

.heading-icon-style {
    .material-symbols-outlined {
        color: #fff;
        font-size: 29px;
    }
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 47px !important;
}

.leftnav-logobox {
    justify-content: space-between !important;
    background-color: #f5f5f5;
}

.css-14yj0ov-MuiPaper-root-MuiAppBar-root,
.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    box-shadow: 9px -3px 13px 7px rgba(0, 0, 0, 0.1) !important;
}

.header-container {
    background-color: #f5f5f5 !important;
    color: #000 !important;
    box-shadow: 9px -3px 13px 7px rgba(0, 0, 0, 0.1) !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    box-shadow: -10px -2px 18px 12px rgba(0, 0, 0, 0.1) !important;
    border: 0px !important;
}

.header-new-label {
    display: flex;
    align-items: center;
}

.btn-light {
    background-color: #191818 !important;
    border: 1px solid #191818 !important;
    color: #fff !important;
}

.home-container .material-symbols-outlined {
    font-size: 25px;
    font-weight: 600;
}

.nav-container i {
    font-size: 20px;
    color: #000;
    position: relative;
    top: -4px;
}

table tr th {
    font-weight: 600;
    font-size: 14px;
}

table tr td {
    font-weight: 400;
    font-size: 14px;
}

.form-label {
    font-size: 14px;
}

.breadcrumb-item {
    font-size: 13px;
}

.text-error {
    font-size: 12px;
    color: red;
    margin-bottom: 0px;
}

.card-body {
    padding: 1em 2em 2em;
}

.card-title {
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    padding-bottom: 0px !important;
}

.planner_link {
    position: relative;
    top: 13px;
    left: 2em;

    .breadcrumb-item {
        font-size: 18px;
        font-weight: bolder;
        color: #515151;

        a {
            color: #515151;
            cursor: pointer;
        }
    }
}

.planner-header div {
    background-color: #1c5230;
    padding: 10px;
    margin-right: 10px;
    margin: 0px 15px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;
}

.planner-column div ul li {
    margin-bottom: 10px;
    cursor: pointer;
    border-top-width: 1px !important;
    font-weight: 600;
}

.planner-column div ul li:hover {
    background-color: #efefef;
    color: #000000;
}

.select-list.list-group-item.active {
    background-color: #efefef !important;
    color: #000000 !important;
    border: 1px solid #00000020 !important;
}

.planner-company-header {
    padding: 9px 15px;
    background-color: #000;
    margin-bottom: 14px;
    color: #fff;
    border-radius: 5px;
}

.projectequip-inactive {
    cursor: auto !important;
}

.heading-right-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: space-around;
}

.location-flex-container {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
}

.location-flex-card {
    background-color: #fff;
}

.location-flex-section {
    padding: 15px 0px 0px 25px;
}

.location-ptitle {
    background-color: #191818;
    color: #fff;
    padding: 5px 10px;
    text-align: center;

    span {
        label {
            font-weight: 600;
        }
    }
}

.adddens-style {

    .form-inline {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .form-inline label {
        margin: 5px 10px 5px 0;
    }

    .form-inline input {
        vertical-align: middle;
        margin: 5px 10px 5px 0;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ddd;
    }

    .button-section {
        margin: 3%;
    }
    // .button {
    //   padding: 10px 20px;
    //   background-color: dodgerblue;
    //   border: 1px solid #ddd;
    //   color: white;
    //   cursor: pointer;
    // }
    // .button:hover {
    //   background-color: royalblue;
    // }

    .remove {
        background-color: rgb(192, 53, 53);
    }

    .remove:hover {
        background-color: rgb(187, 43, 43);
    }

    .btn-danger {
        height: 34px;
        padding: 7px;

        .material-symbols-outlined {
            font-weight: 600;
            font-size: 20px;
        }
    }
}
