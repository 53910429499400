body {
    font-size: .875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.img-width {
    width: -webkit-fill-available !important;
}

.bg-light, .navbar-brand {
    background-color: #F8F8F8 !important;
    box-shadow: 2px 3px 13px -4px rgb(0 0 0 / 20%);
}

.bg-dark {
    background-color: white !important;
}

.card-form {
    width: 100%;
    text-align: left;
}

.form-layout {
    text-align: left;
}

h4 {
    margin: 0;
}

.btn-secondary {
    background-color: #1C5230 !important;
    border: 1px solid #1C5230 !important;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
}

.btn-primary {
    background-color: #1C5230 !important;
    border: 1px solid #1C5230 !important;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
}

.btn-light {
    background-color: #fff;
    border: 1px solid #1C5230;
    padding-left: 20px;
    padding-right: 20px;
}

.card {
    border: 0px !important;
}


header h3 {
    // flex-grow: 1;
    // display: flex;
    // margin-left: 15px;
}



.logocard-innerpage {
    //  background-image:url('../image/g4_logo_bg.png');
    position: absolute;
    // width: 85px;
    // height: 150px;
    width: 100%;
    height: 104px;
    // background-size: 100%;
    // background-repeat: no-repeat;
    top: 0px;
    //margin-left: 30%;
    background: #efefef;

    img {
        width: 199px;
        // margin-top: 15px;
        margin-left: 21px;
    }
}

.sub-logo {
    color: #1C5230;
    width: auto;
    height: 50px;
}

.navbar-brand {
    height: 55px;
}

input {
    // border-top: 0px!important;
    // border-right: 0px!important;
    // border-left: 0px!important;
    // border-bottom: 2px solid #1C5230!important;
    // background-color: #F3F3F3!important;
}

.nav-item {
    text-align: left;
}

@media (min-width: 576px) {
    .ms-sm-auto {
        margin-left: auto !important;
    }
}

.css-tlelie-MuiListItemText-root {
    flex: none !important;
}



.pos-layout {
    position: relative;
}

.banner-text {
    position: absolute;
    color: #fff;
    line-height: 4.5em;
}

.banner-text .subbanner-txtsmall {
    font-size: 2em;
    font-weight: 500;
}

.banner-text .subbanner-txtbig {
    font-size: 5.5em;
}

.nav-link {
    padding: 0.5rem 1rem;
}

.form-label {
    font-weight: 500;
}

.sidebar {
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

.sidebar-menu {
    padding-top: 0;
    flex-direction: column;
    grid-area: sidebar;
    overflow-y: auto;
}

.rounded-circle-new {
    padding: 7px;
    background-color: #e0efe5;
}

.table-btn-ic {
    border: 0px;
    background-color: #e1e2e2;
    border-radius: 50%;
    padding: 3px;
    width: 30px;
    height: 30px;
    color: #5b5757;
}

.table-btn-ic:hover {
    background-color: #1C5230;

    i {
        color: #fff;
    }
}

/*.btn-custom-link {
  color: #191818 !important;
  text-decoration: none !important;
  font-size: 0.9em !important;
  font-weight: 600!important;

}*/

/*.home-card {
    height: 200px !important;
    cursor: pointer;

    p {
        font-size: 0.9em;
        color: #060606;
        word-break: break-all;
        white-space: normal
    }

    .card-title {
        font-size: 1.2em;
        color: #212121;
        font-weight: 600;
    }

    .slash-text {
        .num {
            font-size: 1.5em;
            font-weight: 700;
            position: relative;
            top: 5px;
        }

        .created {
            font-size: 0.9em;
            font-weight: 600;
            color: #807f7f;
            text-transform: capitalize;
        }
    }
}*/
.btn-custom-link {
    color: #fff !important;
    text-decoration: none !important;
    font-size: 0.9em !important;
    font-weight: 500 !important;
}

.home-card {
    max-height: 126px !important;
    cursor: pointer;
    background-color: #17a2b8 !important;
    color: #fff !important;
    margin-bottom: 20px !important;

    p {
        font-size: 0.9em;
        color: #060606;
        word-break: break-all;
        white-space: normal
    }

    .card-title {
        font-size: 1.2em;
        color: #fff !important;
        font-weight: 500;
    }

    .material-symbols-outlined {
        font-weight: 500 !important;
    }

    .slash-text {
        .num {
            font-size: 1.5em;
            font-weight: 700;
            position: relative;
            top: 5px;
        }

        .created {
            font-size: 0.9em;
            font-weight: 600;
            color: #807f7f;
            text-transform: capitalize;
        }
    }
}

.card-tone-2 {
    background-color: #b9edcc !important;
}

.home-header-txt {
    font-weight: 600;
    color: #515151;
}

.main-wrapper {
    position: fixed;
}

.bg-cover-c {
    background-color: #efefef;
}

.heading-icon-style {
    background-color: #17a2b8;
    border-radius: 50%;
    padding: 8px 15px;
    width: 50px;
    height: 50px;
    display: flex;
    align-content: flex-start;
    justify-content: space-around;
    align-items: center;
    margin-right: 11px;
    margin-top: -11px;
        padding-left: 11px;

    i {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
    }
}

.heading-right-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.react-confirm-alert-body > h1 {
    font-size: 1.5rem !important;
}
